<template>
  <section class="min-w1600">
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <button type="button" class="btn-layout btn-grey" @click="detailModalOpen('c')">{{ $t('button.add') }}</button>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="tableData.length !== 0">
              <tr v-for="(item, idx) in tableData" :key="idx">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>{{ item.bankNameKr }}</td>
                <td>{{ item.bankCode }}</td>
                <td>{{ item.bankDomain }}</td>
                <td class="tableInnerImg" width="120px">
                  <img :src="item.bankImg" v-if="item.bankImg" />
                  <span v-else>-</span>
                </td>
                <td>
                  <span class="roboto">{{ item.inspectTimeStart }}</span>
                  ~
                  <span class="roboto">{{ item.inspectTimeEnd }}</span>
                </td>
                <td>{{ item.useYn ? "활성" : "비활성" }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="detailModalOpen('u', item)">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="delBank(item.bankCode)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="8">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3 v-if="modalType === 'u'">{{ $t('button.modify') }}</h3>
            <h3 v-if="modalType === 'c'">{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.bankCode') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.bankCode" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.bankKr') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.bankNameKr" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.bankEn') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.bankNameEn" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.site') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.bankDomain" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.logoImg') }}</div>
                <div class="item-content">
                  <input type="file" @change="readFile" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.maintainTime') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.inspectTimeStart" />
                  ~
                  <input type="text" v-model="saveData.inspectTimeEnd" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.status') }}</div>
                <div class="item-content">
                  <input type="checkbox" class="set-switch" :checked="saveData.useYn" v-model="saveData.useYn" />
                </div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="updateBank" v-if="modalType === 'u'">{{ $t('button.save') }}</a>
              <a @click="saveBank" v-if="modalType === 'c'">{{ $t('button.save') }}</a>
              <a @click="modalClose()">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import typing from '@/components/ui/Typing'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import { getSiteData } from '@/libs/auth-helper'
import { comBank, comBankSave } from '@/api/member.js'
export default {
  name: 'CompanyBank',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'kr', 'type', 'site', 'logoImg', 'maintain', 'status', 'modify']
      },
      reqData: {
      },
      tableData: [],
      modalType: '',
      saveData: {
        bankCode: '',
        bankNameKr: '',
        bankNameEn: '',
        bankDomain: '',
        bankImg: '',
        useYn: false,
        inspectTimeStart: '',
        inspectTimeEnd: ''
      },
      updModalActive: false
    };
  },
  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async readFile(e) {
      const target = e.target;
      const base64 =  await this.toBase64(target.files[0])
      this.saveData.bankImg = base64;
    },
    async saveBank() {
      const siteInfo = this.getSiteInfo();
      const trigger = confirm("해당 게시물을 등록 하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = this.saveData;
      if (req.useYn) {
        req.useYn = "Y";
      } else {
        req.useYn = "N";
      }
      const res = await comBankSave(req);
      if (res.resultCode === "0") {
        alert("추가 완료");
        this.setTableData();
        this.modalClose();
        this.saveData = {
          bankCode: "",
          bankNameKr: "",
          bankNameEn: "",
          bankDomain: "",
          bankImg: "",
          useYn: false,
          inspectTimeStart: "",
          inspectTimeEnd: ""
        };
      } else {
        alert("추가 실패, 다시 시도해주세요.");
      }
    },
    async updateBank() {
      const siteInfo = this.getSiteInfo();
      const trigger = confirm("해당 게시물을 수정 하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = lodash.cloneDeep(this.saveData);
      if (req.useYn) {
        req.useYn = "Y";
      } else {
        req.useYn = "N";
      }
      if (req.delYn) {
        req.delYn = "Y";
      } else {
        req.delYn = "N";
      }
      const res = await comBankSave(req);
      if (res.resultCode === "0") {
        alert("수정 완료");
        this.setTableData();
        this.modalClose();
        this.saveData = {
          bankCode: "",
          bankNameKr: "",
          bankNameEn: "",
          bankDomain: "",
          bankImg: "",
          useYn: false,
          inspectTimeStart: "",
          inspectTimeEnd: ""
        };
      } else {
        alert("수정 실패, 다시 시도해주세요.");
      }
    },
    async delBank(bankCode) {
      const siteInfo = this.getSiteInfo();
      const trigger = confirm("해당 게시물을 삭제 하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = {
        bankCode: bankCode,
        delYn: "Y"
      };
      const res = await comBankSave(req);
      if (res.resultCode === "0") {
        alert("삭제 완료");
        this.setTableData();
        this.modalClose();
        this.saveData = {
          bankCode: "",
          bankNameKr: "",
          bankNameEn: "",
          bankDomain: "",
          bankImg: "",
          useYn: false,
          inspectTimeStart: "",
          inspectTimeEnd: ""
        };
      } else {
        alert("삭제 실패, 다시 시도해주세요.");
      }
    },
    modalClose() {
      this.updModalActive = false;
      this.saveData = {
        bankCode: "",
        bankNameKr: "",
        bankNameEn: "",
        bankDomain: "",
        bankImg: "",
        useYn: false,
        inspectTimeStart: "",
        inspectTimeEnd: ""
      };
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    detailModalOpen(type, item) {
      this.modalType = type;
      if (type === "c") {
        this.saveData = {};
      } else if (type === "u") {
        for (const key in item) {
          this.saveData[key] = item[key];
        }
      }
      this.updModalActive = true;
    },
    async setTableData() {
      let req = {  };
      const siteInfo = this.getSiteInfo();
      if (siteInfo) {
        req.siteId = siteInfo.siteId;
      }

      const res = await comBank(req);
      this.tableData = res.data.list;
      for (const item of this.tableData) {
        for (const key in item) {
          if (item[key] === "Y") {
            item[key] = true;
          } else if (item[key] === "N") {
            item[key] = false;
          }
        }
      }
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo;
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count);
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1);
      }
    }
  },
  async created() {
    this.setTableData();
  }
};
</script>

<style scoped>
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.pt50 {
  padding-top: 50px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.item-wrap > div.item-title {
  min-width: 110px;
}
.tableInnerImg{
  width:120px;
}
.tableInnerImg img{
  width:100%;
}
.mainTable tbody tr:nth-child(even) td {
  background: #f5f5f5;
}
</style>
